import {Link} from 'gatsby'
import React from 'react'
import {Fade} from 'react-reveal'
import Layout from '../components/Layout'
import {Seo} from '../components/Seo'

const StrefaFitness = () => {
  return (
    <Layout>
      <Seo title='Strefa Fitness | Modelowanie Sylwetki' />

      <div className='page-container'>
        <div className='page'>
          <div className='content-1'>
            <div>
              <h1>STREFA FITNESS</h1>
              <div className='section'>
                <div>
                  <h2>VACU BIEŻNIA IR PROFFESIONAL</h2>
                  <p>
                    VACU BIEŻNIA z IR to urządzenie profesjonalnej bieżni zamkniętej w
                    specjalnej kapsule z podciśnieniem i podczerwienią (IR), gdzie zgubisz
                    zbędne kilogramy nawet bez zastosowania diety. Podczas treningu na
                    VACU BIEŻNI IR dochodzi do spalania tkanki tłuszczowej w dolnych
                    partiach ciała, takich jak: brzuch, biodra, uda, pośladki czy łydki.
                    Promienie podczerwone nagrzewają ciało i rozszerzają naczynia
                    krwionośne czego efektem jest zwiększenie przepływu krwi, lepsze
                    dotlenienie i ukrwienie tkanek. Światło podczerwone nagrzewając skórę
                    powoduje przyspieszenie przemiany materii oraz pomaga wiązać wolne
                    rodniki powodując efektywne oczyszczanie organizmu, usuwanie
                    podskórnej tkanki tłuszczowej i zmniejszenie cellulitu. Przemiana
                    materii zostaje przyspieszona a podciśnienie wpływa korzystnie na
                    układ krwionośny, poprawia krążenie limfy, skutkiem czego skóra staje
                    się wyraźnie wygładzona i jędrniejsza. Zastosowane w urządzeniu
                    podciśnienie powoduje znaczący wzrost spalania tkanki tłuszczowej,
                    poprawę krążenia krwi w tkankach podskórnych oraz poprawę krążenia
                    limfy odpowiedzialnej za usuwanie z organizmu toksyn i nadmiernej
                    ilości płynów.
                  </p>
                </div>
              </div>

              <div className='section'>
                <div>
                  <h2>ROLLMASAŻER Z IR</h2>
                  <p>
                    ROLLmasażer z IR to nowoczesne urządzenie służące do modelowania
                    sylwetki, redukcji cellulitu i tkanki tłuszczowej oraz do ujędrnienia
                    skóry. Urządzenie to wspomaga odchudzenia, daje widoczne efekty a sam
                    zabieg jest bardzo relaksujący. Jest to mechaniczny masaż za pomocą
                    profilowanych drewnianych rolek, które obracając się na odpowiednio
                    dobranych obrotach, masują poszczególne partie ciała. Ważne, aby
                    podczas treningu wykonywać ćwiczenia zgodnie z ustalonym planem,
                    koniecznie w kierunku zgodnym z kierunkiem przepływu limfy.
                    Zastosowane w urządzeniu światło czerwone dodatkowo podgrzewa naszą
                    skórę, przyspieszając spalanie tkanki tłuszczowej, zwiększa przepływ
                    krwi dając lepsze dotlenienie i ukrwienie tkanek. Podczerwień
                    oczyszcza skórę i tkanki podskórne ze zbędnych toksyn czego efektem
                    jest jędrna, zdrowa i sprężysta skóra. Efekty widoczne są już po
                    pierwszych kilku treningach.
                  </p>
                </div>
              </div>

              <div className='section'>
                <div>
                  <h2>SWAN ORBITREK IR z LAMPAMI KOLAGENOWYMI</h2>
                  <p>
                    Urządzenie to łączy w sobie cztery komponenty, a połączenie ich w
                    jednym urządzeniu powoduje, że podczas treningu działamy na całe
                    ciało, gdzie jedno działanie potęguje drugie i uzupełnia kolejne. SWAN
                    ORBITREK IR Z KOLAGENEM to Orbitrek pionowy umieszczony w specjalnej
                    kapsule wykonanej z polietylenu, w obudowie którego zastosowano
                    dodatkowo lampy z podczerwienią IR, lampy kolagenowe i lampy UVB,
                    które podnoszą ilość witaminy D w naszym organizmie. Trening na tym
                    urządzeniu ma szereg zalet: spala tkankę tłuszczową, poprawia
                    wydolność, wzmacnia wszystkie najważniejsze partie mięśniowe, nie
                    obciąża stawów i minimalizuje ryzyko kontuzji. Trening jest zalecany
                    każdemu niezależnie od wieku i stopnia sprawności fizycznej.
                    Jednoczesne działanie głęboko penetrująco – oczyszczające,
                    odbudowujące, poprawiające elastyczność i jędrność skóry, daje efekty
                    spektakularne i długotrwałe.
                  </p>
                </div>
              </div>

              <div className='section'>
                <div>
                  <h2>ROWER POZIOMY</h2>
                  <p>
                    Rower poziomy doskonale sprawdza się w przypadku osób, które narzekają
                    na bóle pleców. Ustawienia ciała w trakcie ćwiczeń pozwala na
                    odciążenie kręgosłupa co jest szczególnie istotne dla osób zmagających
                    się na co dzień z dużą wagą. Tym samym sprawdza się znakomicie zarówno
                    w przypadku redukcji wagi jak i rehabilitacji. Wiele osób kojarzy
                    jazdę na rowerze z pracą mięśni nóg, ale jest to tylko część prawdy –
                    rower poziomy nie tylko wzmocni i wysmukli nogi ale również wzmocni
                    mięśnie brzucha oraz mięśnie pośladkowe.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Fade left>
            <Link to='/kontakt'>
              <button>Umów Wizytę</button>
            </Link>
          </Fade>
        </div>
      </div>
    </Layout>
  )
}

export default StrefaFitness
